import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import config from '../config';
import { authService } from '../Services/AuthService'
import { User } from '../Models/User'

const { api } = config;

// default
axios.defaults.baseURL = api.API_URL;
// content type
// axios.defaults.headers.post['Content-Type'] = 'application/json';
// axios.defaults.headers.common['Access-Control-Allow-Origin'] =
//     'http://localhost:3000';
// axios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true';

// content type
const token: any = localStorage.getItem('token');
if (token) axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

// intercepting to capture errors
axios.interceptors.response.use(
    function (response) {
        return response.data ? response.data : response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            case 404:
                message = 'Sorry! the data you are looking for could not be found';
                break;
            default:
                message = error.message || error;
        }
        return Promise.reject(message);
    }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string) => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
};

class APIClient {
    /**
     * Fetches data from the given URL
     */
    get<T>(url: string, params?: any): Promise<T> {
        let response: Promise<T>;

        let paramKeys: string[] = [];

        if (params) {
            Object.keys(params).map((key) => {
                paramKeys.push(key + '=' + params[key]);
                return paramKeys;
            });

            const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : '';
            response = axios.get(`${process.env.REACT_APP_API_URL}${url}?${queryString}`, params);
        } else {
            response = axios.get(`${process.env.REACT_APP_API_URL}${url}`, params);
        }

        return response;
    }

    /**
     * Posts the given data to the URL
     */
    create<T>(url: string, data: any): Promise<T> {
        return axios.post(`${process.env.REACT_APP_API_URL}${url}`, data);
    }

    /**
     * Updates data
     */
    update<T>(url: string, data: any): Promise<T> {
        return axios.patch(`${process.env.REACT_APP_API_URL}${url}`, data);
    }

    put<T>(url: string, data: any): Promise<T> {
        return axios.put(`${process.env.REACT_APP_API_URL}${url}`, data);
    }

    /**
     * Deletes data
     */
    delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        return axios.delete(`${process.env.REACT_APP_API_URL}${url}`, {
            ...config,
        });
    }
}

const getLoggedinUser = () => {
    const user = localStorage.getItem('authUser');
    if (!user) {
        return null;
    } else {
        const parsedUser = JSON.parse(user)
        authService.user = new User(parsedUser);
        return parsedUser;
    }
};

export { APIClient, setAuthorization, getLoggedinUser };
