export const formattedDate = (value: string) => {
    const date = new Date(value);
    const weekdayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const dateString =
        date.getHours() +
        ':' +
        ('00' + date.getMinutes()).slice(-2) +
        ', ' +
        date.getDate() +
        ' ' +
        monthNames[date.getMonth()];

    return dateString;
};
