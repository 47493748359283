import { Button, Form, FormFeedback, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { createDeleteGameDataSelector } from 'selectors/SelectedGamesSelectors';
import { deleteSelectedGame } from 'slices/client-games/thunk';
import { resetSelectedGamesReducerStatus } from 'slices/client-games/reducer';

export const DeleteGameDialog = ({
    isOpen,
    onClose,
    game,
}: {
    isOpen: boolean;
    onClose: (refresh?: boolean) => void;
    game?: any;
}) => {
    const dispatch: any = useDispatch();

    const { message, success, isInProgress } = useSelector(createDeleteGameDataSelector);



    const handleClose = (refresh = false) => {
        onClose(refresh)
    }

    const handleDelete = (refresh = true) => {
        dispatch(deleteSelectedGame(game.id))
        onClose(refresh)
    }

    useEffect(() => {
        if (!isInProgress && success !== null) {
            if (success === true) {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-success text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetSelectedGamesReducerStatus());
                    },
                });

                handleClose(true);
            } else {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-danger text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetSelectedGamesReducerStatus());
                    },
                });
            }
        }
    }, [isInProgress]);

    return (
        <Modal
            isOpen={isOpen}
            toggle={() => {
                handleClose();
            }}
            backdrop={'static'}
            id="staticBackdrop"
            centered
            className="modal-md"
        >
            <ModalBody
                className="modal-title"
                id="myModalLabel"
                toggle={() => {
                    !isInProgress && handleClose();
                }}
            >
                Are you sure you want to delete {game?.name || 'game'}?
            </ModalBody>
            <ModalFooter>
                <Button color="light" onClick={() => handleClose()} disabled={isInProgress}>
                    Cancel
                </Button>
                <Button color="primary" onClick={() => handleDelete()} type="submit" disabled={isInProgress}>
                    {isInProgress ? (
                        <span className="d-flex align-items-center">
                            <Spinner className="top-25 start-50" animation="border" color="light" />
                            <span className="flex-grow-1 ms-2">Loading...</span>
                        </span>
                    ) : (
                        'Delete'
                    )}
                </Button>
            </ModalFooter>
        </Modal>
    );
};
