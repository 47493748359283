import { BaseApiService } from '../BaseApiService';
import { IApiCommonResponse, IFetchAllJobsApiRequestBody, IReRunJobApiRequestBody } from '../../../slices/types';
import { IFetchAllJobsApiResponse } from './IJobApiService';

export class JobApiService {
    static GET_JOBS_URL = '/jobs';
    static POST_RERUN_JOB_URL = '/jobs/rerun/';
    static DELETE_JOB_URL = '/jobs/';
    static GET_JOBS_STATS_URL = '/jobs/stats';

    static fetchAll(params: IFetchAllJobsApiRequestBody) {
        return BaseApiService.get<IFetchAllJobsApiResponse>(JobApiService.GET_JOBS_URL, params);
    }

    static reRun({ original_id }: IReRunJobApiRequestBody) {
        return BaseApiService.create<IApiCommonResponse>(JobApiService.POST_RERUN_JOB_URL + original_id);
    }

    static delete(id: number) {
        return BaseApiService.delete<IApiCommonResponse>(JobApiService.DELETE_JOB_URL + id);
    }

    static fetchJobsStats() {
        return BaseApiService.get<IApiCommonResponse>(JobApiService.GET_JOBS_STATS_URL);
    }
}
