import React, { useEffect, useState } from 'react';
import { Alert, Badge, Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGamesOnOperator } from '../../../slices/reports/thunk';
import { gamesOnOperatorDataSelector } from '../../../selectors/reportsSelectors';
import { DropDown } from '../../../Components/Common/DropDown';
import { loadProviderSuggestions } from '../../../helpers/loadProviderSuggestions';
import { AllGamesDialog } from '../Portfolio/AllGamesDialog';
import { useProfile } from '../../../Components/Hooks/UserHooks';
import { SelectSelectedGame } from '../../../Components/Selects/SelectSelectedGame';
import { SelectSelectedOperator } from '../../../Components/Selects/SelectSelectedOperator';
import { resetGamesOnSectionsAction } from '../../../slices/reports/reducer';
import { IReportGamesOnOperatorSectionRow } from '../../../Services/Api/IReportApiService';

export const GamesOnOperator = () => {
    const dispatch: any = useDispatch();
    const { user } = useProfile();
    const { sections, isInProgress } = useSelector(gamesOnOperatorDataSelector);

    const [selectedSection, setSelectedSection] = useState<IReportGamesOnOperatorSectionRow | null>(null);
    const [selectedProviderId, setSelectedProviderId] = useState(user.providerId);
    const [selectedGameId, setSelectedGameId] = useState(0);
    const [selectedOperatorId, setSelectedOperatorId] = useState(0);

    useEffect(() => {
        if (selectedOperatorId) {
            dispatch(fetchGamesOnOperator(selectedProviderId, selectedGameId, selectedOperatorId));
        } else {
            dispatch(resetGamesOnSectionsAction());
        }
    }, [selectedProviderId, selectedGameId, selectedOperatorId]);

    const smColumns = user?.isAdmin ? 4 : 6;
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                                <BreadCrumb pageTitle={'Reports'} title={'Games Positioning'} />
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    {user.isAdmin && (
                                                        <Col xs={12} sm={smColumns}>
                                                            <DropDown
                                                                handleSelect={(id) => {
                                                                    setSelectedProviderId(id);
                                                                }}
                                                                isInProgress={isInProgress}
                                                                loadSuggestions={() => loadProviderSuggestions()}
                                                                placeholder="* Filter By Provider..."
                                                                hideImage={true}
                                                            />
                                                        </Col>
                                                    )}
                                                    <Col xs={12} sm={smColumns}>
                                                        <SelectSelectedGame
                                                            setValue={setSelectedGameId}
                                                            isInProgress={isInProgress}
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={smColumns}>
                                                        <SelectSelectedOperator
                                                            setValue={setSelectedOperatorId}
                                                            isInProgress={isInProgress}
                                                            placeholder="* Filter By Operator..."
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                {!selectedOperatorId || !selectedProviderId ? (
                                                    <Alert color="primary" className="mb-0">
                                                        Please select required filters
                                                    </Alert>
                                                ) : isInProgress ? (
                                                    <></>
                                                ) : sections.length === 0 ? (
                                                    <Alert color="primary" className="mb-0">
                                                        No data found for selected filters
                                                    </Alert>
                                                ) : (
                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-nowrap align-middle mb-0">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th>Section</th>
                                                                    <th>Game Count</th>
                                                                    <th>Games</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {sections.map(({ section, games }, index) => {
                                                                    return (
                                                                        <tr key={`section-info-${section.id}`}>
                                                                            <td className="fs-5">{section.name}</td>
                                                                            <td>
                                                                                <span>{section.game_count}</span>
                                                                            </td>
                                                                            <td
                                                                                className={
                                                                                    games.length > 0
                                                                                        ? 'display-flex'
                                                                                        : ''
                                                                                }
                                                                            >
                                                                                {games.slice(0, 4).map((game) => {
                                                                                    return (
                                                                                        <div
                                                                                            className="game-item"
                                                                                            key={`game-info-${section.id}-${game.id}`}
                                                                                        >
                                                                                            {game.name}{' '}
                                                                                            <Badge color="success">
                                                                                                {game.position}
                                                                                            </Badge>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                                {games.length > 4 ? (
                                                                                    <Button
                                                                                        onClick={() => {
                                                                                            setSelectedSection({
                                                                                                section,
                                                                                                games,
                                                                                            });
                                                                                        }}
                                                                                        color="primary"
                                                                                    >
                                                                                        . . .
                                                                                    </Button>
                                                                                ) : games.length === 0 ? (
                                                                                    <Badge color="warning">
                                                                                        No Game
                                                                                    </Badge>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <AllGamesDialog
                isOpen={!!selectedSection}
                onClose={() => {
                    setSelectedSection(null);
                }}
                itemKey={`section-${selectedSection?.section.id}`}
                title={`All games on ${selectedSection?.section.name} (${selectedSection?.section.game_count}) section`}
                games={selectedSection?.games}
            />
        </React.Fragment>
    );
};
