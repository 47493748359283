import { DropDown } from '../Common/DropDown';
import React from 'react';
import { loadGameSuggestions } from '../../helpers/loadGameSuggestions';
import { loadSelectedOperatorSuggestions } from '../../helpers/loadSelectedOperatorSuggestions';
import { loadSelectedGameSuggestions } from '../../helpers/loadSelectedGameSuggestions';
import { useSelector } from 'react-redux';
import { userRelatedDataSelector } from '../../selectors/accountSelectors';
import { IUserRelatedGame } from '../../Services/Api/Account/IAccountApiService';

type SelectSelectedGameProps = {
    setValue: (id: number) => void;
    isInProgress: boolean;
    placeholder?: string;
    providerId?: number;
    isMulti?: boolean;
};

export const SelectSelectedGame = ({
    setValue,
    isInProgress,
    placeholder = 'Filter By Game...',
    providerId = 0,
    isMulti = false,
}: SelectSelectedGameProps) => {
    const { games } = useSelector(userRelatedDataSelector);

    return (
        <DropDown
            handleSelect={(id) => {
                setValue(id);
            }}
            defaultOptions={games.map((game: IUserRelatedGame) => {
                return { label: game.name, value: game.id };
            })}
            isInProgress={isInProgress}
            loadSuggestions={() => loadSelectedGameSuggestions(providerId)}
            placeholder={placeholder}
            hideImage={true}
            isMulti={isMulti}
        />
    );
};
