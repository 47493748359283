import React, { useEffect, useState } from 'react';
import { Alert, Badge, Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGamesOnSections } from '../../../slices/reports/thunk';
import { gamesOnSectionsDataSelector } from '../../../selectors/reportsSelectors';
import { useProfile } from '../../../Components/Hooks/UserHooks';
import { SelectSelectedGame } from '../../../Components/Selects/SelectSelectedGame';
import { SelectSelectedOperator } from '../../../Components/Selects/SelectSelectedOperator';

export const GamesOnSections = () => {
    const dispatch: any = useDispatch();
    const { user } = useProfile();

    const { operators, isInProgress } = useSelector(gamesOnSectionsDataSelector);

    const [selectedGameId, setSelectedGameId] = useState(0);
    const [selectedOperatorId, setSelectedOperatorId] = useState(0);

    useEffect(() => {
        dispatch(fetchGamesOnSections(selectedGameId, selectedOperatorId));
    }, [selectedGameId, selectedOperatorId]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                                <BreadCrumb pageTitle={'Reports'} title={'Games on Sections'} />
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col xs={12} sm={6}>
                                                        <SelectSelectedGame
                                                            setValue={setSelectedGameId}
                                                            isInProgress={isInProgress}
                                                            placeholder="* Filter By Game..."
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        <SelectSelectedOperator
                                                            setValue={setSelectedOperatorId}
                                                            isInProgress={isInProgress}
                                                            placeholder="Filter By Operator..."
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                {!selectedGameId ? (
                                                    <Alert color="primary" className="mb-0">
                                                        Please select a game
                                                    </Alert>
                                                ) : isInProgress ? (
                                                    <></>
                                                ) : operators.length === 0 ? (
                                                    <Alert color="primary" className="mb-0">
                                                        No data found for selected filters
                                                    </Alert>
                                                ) : (
                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-nowrap align-middle mb-0">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th className="min-w">#</th>
                                                                    <th>Operator</th>
                                                                    <th>Sections</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {operators.map(({ operator, sections }, index) => {
                                                                    return (
                                                                        <tr key={`section-info-${operator.id}`}>
                                                                            <td>{index + 1}</td>
                                                                            <td className="fs-5">{operator.name}</td>
                                                                            <td className="display-flex">
                                                                                {sections.map((section) => {
                                                                                    return (
                                                                                        <div
                                                                                            key={`section-${section.id}-${operator.id}`}
                                                                                            className="game-item"
                                                                                        >
                                                                                            {section.name}{' '}
                                                                                            <Badge color="success">
                                                                                                {section.game.position}/
                                                                                                {section.game_count}
                                                                                            </Badge>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
