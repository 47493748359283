import eu from '../assets/images/flags/eu.svg';
import am from '../assets/images/flags/am.svg';
import br from '../assets/images/flags/br.svg';
import ar from '../assets/images/flags/ar.svg';
import fr from '../assets/images/flags/fr.svg';
import be from '../assets/images/flags/be.svg';
import bg from '../assets/images/flags/bg.svg';

const flags = {
    am: {
        label: 'Armenia',
        flag: am,
    },
    br: {
        label: 'Brazil',
        flag: br,
    },
    ar: {
        label: 'Argentina',
        flag: ar,
    },
    fr: {
        label: 'France',
        flag: fr,
    },
    be: {
        label: 'Belgium',
        flag: be,
    },
    bg: {
        label: 'Bulgaria',
        flag: bg,
    },
};

export default flags;
