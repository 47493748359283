import { createSelector } from 'reselect';
import { IGamesState } from '../slices/types';

export const gamesStateSelector = (state: any): IGamesState => state.Games;

export const allGamesDataSelector = createSelector(gamesStateSelector, (games) => ({
    data: games.allGames.data,
    isInProgress: games.isInProgress.fetchAllGames,
    totalPages: games.allGames.pagination.totalPages,
}));

export const notFoundGamesDataSelector = createSelector(gamesStateSelector, (games) => ({
    data: games.notFoundGames.data,
    totalPages: games.notFoundGames.pagination.totalPages,
    isInProgress: games.isInProgress.fetchNotFoundGames,
}));

export const matchGamesDataSelector = createSelector(gamesStateSelector, (games) => {
    return {
        success: games.success,
        message: games.message,
        isInProgress: games.isInProgress.matchGames,
        isCreateAndMatchGameInProgress: games.isInProgress.createAndMatchGame,
    };
});
