import {
    setOperatorsInProgressAction,
    fetchAllOperatorsSuccessAction,
    createOperatorSuccessAction,
    createOperatorFailedAction,
    updateOperatorSuccessAction,
    updateOperatorFailedAction,
} from './reducer';
import { fetchAllOperatorsApi } from '../../helpers/backend_helper';
import { ICreateOperatorApiRequestBody, IFetchAlloperatorsApiRequestBody } from '../types';
import { setGlobalInProgressAction } from '../global/reducer';
import { OperatorApiService } from '../../Services/Api/OperatorApiService';

export const fetchAllOperators = (params: IFetchAlloperatorsApiRequestBody) => async (dispatch: any) => {
    dispatch(setOperatorsInProgressAction('fetchAllOperators'));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await fetchAllOperatorsApi(params);

        if (data.code === 101) {
            dispatch(fetchAllOperatorsSuccessAction(data));
        }
    } catch (error) {}
    dispatch(setGlobalInProgressAction(false));
};

export const createOperator = (params: ICreateOperatorApiRequestBody) => async (dispatch: any) => {
    dispatch(setOperatorsInProgressAction('createOperator'));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await OperatorApiService.create(params);

        if (data.code === 101) {
            dispatch(createOperatorSuccessAction(data));
        } else {
            dispatch(createOperatorFailedAction(data));
        }
    } catch (error) {}
    dispatch(setGlobalInProgressAction(false));
};

export const updateOperator = (id: number, params: ICreateOperatorApiRequestBody) => async (dispatch: any) => {
    dispatch(setOperatorsInProgressAction('updateOperator'));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await OperatorApiService.update(id, params);

        if (data.code === 101) {
            dispatch(updateOperatorSuccessAction(data));
        } else {
            dispatch(updateOperatorFailedAction(data));
        }
    } catch (error) {}
    dispatch(setGlobalInProgressAction(false));
};
