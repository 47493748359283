import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

//import images
import avatar1 from '../../assets/images/users/avatar-1.jpg';
import { logoutUser } from '../../slices/auth/login/thunk';
import { useProfile } from '../Hooks/UserHooks';

const ProfileDropdown = () => {
    const dispatch: any = useDispatch();
    const navigate = useNavigate();

    const { user } = useProfile();

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    const logout = () => {
        dispatch(logoutUser(navigate));
    };

    return (
        <React.Fragment>
            <Dropdown
                isOpen={isProfileDropdown}
                toggle={toggleProfileDropdown}
                className="ms-sm-3 header-item topbar-user"
            >
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        {/*<img className="rounded-circle header-profile-user" src={avatar1} alt="Header Avatar" />*/}
                        {/*<span className="text-start ms-xl-2">*/}
                        <span className="text-start">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                                {user?.fname || 'Admin'}
                            </span>
                            {/*<span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">Founder</span>*/}
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem className="p-0">
                        <Link to="/pages-profile-settings" className="dropdown-item">
                            <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{' '}
                            <span className="align-middle">Settings</span>
                        </Link>
                    </DropdownItem>
                    <DropdownItem className="p-0">
                        <div className="dropdown-item" onClick={logout}>
                            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{' '}
                            <span className="align-middle" data-key="t-logout">
                                Logout
                            </span>
                        </div>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;
