import { getFirebaseBackend } from '../../../helpers/firebase_helper';

import { loginSuccess, logoutUserSuccess, apiError, reset_login_flag } from './reducer';
import { postLogin } from '../../../helpers/backend_helper';

export const loginUser = (user: any, history: any) => async (dispatch: any) => {
    try {
        const data = await postLogin(user);

        if (data.code === 101) {
            localStorage.setItem('authUser', JSON.stringify(data.user));
            localStorage.setItem('token', data.token!);
            dispatch(loginSuccess(data));
            history('/dashboard');
        }
    } catch (error) {
        dispatch(apiError(error));
    }
};

export const logoutUser = (history: any) => async (dispatch: any) => {
    try {
        localStorage.removeItem('authUser');
        localStorage.removeItem('token');
        dispatch(logoutUserSuccess(true));
        history('/login');
    } catch (error) {}
};

export const socialLogin = (type: any, history: any) => async (dispatch: any) => {
    try {
        let response;

        if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
            const fireBaseBackend: any = getFirebaseBackend();
            response = fireBaseBackend.socialLoginUser(type);
        }
        //  else {
        //   response = postSocialLogin(data);
        // }

        const socialdata = await response;
        if (socialdata) {
            localStorage.setItem('authUser', JSON.stringify(response));
            dispatch(loginSuccess(response));
            history('/dashboard');
        }
    } catch (error) {
        dispatch(apiError(error));
    }
};

export const resetLoginFlag = () => async (dispatch: any) => {
    try {
        const response = dispatch(reset_login_flag());
        return response;
    } catch (error) {
        dispatch(apiError(error));
    }
};
