import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, CardBody, CardHeader, Col, Container, Label, Row, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Pagination } from 'Components/Common/Pagination';
import { Search } from 'Components/Common/Search';
import BreadCrumb from 'Components/Common/BreadCrumb';
import { DropDown } from 'Components/Common/DropDown';
import { loadProviderSuggestions } from 'helpers/loadProviderSuggestions';
import { IFetchSelectedGamesApiRequestBody } from 'slices/types';
import { CreateNewGameDialog } from './GameDialog';
import { selectedGamesDataSelector } from 'selectors/SelectedGamesSelectors';
import { DeleteGameDialog } from './DeleteGameDialog';
import { useProfile } from 'Components/Hooks/UserHooks';
import { fetchSelectedGames } from 'slices/client-games/thunk';

export const Games = () => {
    const dispatch: any = useDispatch();
    const { user } = useProfile();
    const [isCreateNewGameDialogOpen, setIsCreateNewGameDialogOpen] = useState<boolean>(false);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [isFromedit, setIsFromEdit] = useState<boolean>(false);
    const [selectedGame, setSelectedGame] = useState<any>(null);
    const [selectedPage, setSelectedPage] = useState<number>(1);
    const [selectedProviderId, setSelectedProviderId] = useState<any>(user?.isAdmin ? null : user?.providerId);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const { data, isInProgress, totalPages } = useSelector(selectedGamesDataSelector);
    const [params, setParams] = useState<IFetchSelectedGamesApiRequestBody>({
        page: 1,
        q: '',
        provider_id: 0,
        page_size: 10,
    });

    const handleSelectProvider = (providerId: any) => {
        setSelectedProviderId(providerId);
        setSelectedPage(1);
        setParams({ page: 1, q: searchTerm, provider_id: providerId });
    };

    const search = (query: string) => {
        setSearchTerm(query);
        setSelectedPage(1);
        setParams({ ...params, page: 1, q: query });
    };

    const openEditDialog = (game: any): void => {
        setIsFromEdit(true);
        setIsCreateNewGameDialogOpen(true);
        setSelectedGame(game);
    };
    const openDeleteDialog = (game: any): void => {
        setDeleteDialogOpen(true);
        setSelectedGame(game);
    };
    const loadData = () => {
        dispatch(fetchSelectedGames(params));
    };

    useEffect(() => {
        loadData();
    }, [params]);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                                <BreadCrumb title={'Games'} />
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col xs={12} sm={4}>
                                                        <Search search={search} placeholder="Search By Game Name..." />
                                                    </Col>
                                                    <Col xs={12} sm={4}>
                                                        <div className={user.isAdmin ? '' : 'visually-hidden'}>
                                                            <DropDown
                                                                handleSelect={handleSelectProvider}
                                                                isInProgress={isInProgress}
                                                                loadSuggestions={() => loadProviderSuggestions()}
                                                                placeholder="Filter By Provider..."
                                                                hideImage={true}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} sm={4} className="display-flex justify-content-end">
                                                        <Button
                                                            onClick={() => setIsCreateNewGameDialogOpen(true)}
                                                            color="primary"
                                                            className={selectedProviderId ? 'btn' : 'disabled btn'}
                                                        >
                                                            Add new game
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg={12}>
                                        <Card>
                                            <CardHeader>
                                                <h4 className="card-title mb-0">Choices</h4>
                                            </CardHeader>
                                            <CardBody>
                                                {
                                                    <div className="table-responsive">
                                                        <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Name</th>
                                                                    <th scope="col">Type</th>
                                                                    <th scope="col" className="text-center">
                                                                        Status
                                                                    </th>
                                                                    <th scope="col" className="text-end">
                                                                        Actions
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.map((game: any, index: number) => (
                                                                    <tr key={`game-${game.id}`}>
                                                                        <td>{(selectedPage - 1) * 10 + index + 1}</td>
                                                                        <td>{game?.name}</td>
                                                                        <td>{game?.type}</td>
                                                                        <td className="text-center">
                                                                            {game.status === 'completed' && (
                                                                                <Badge color="success">Completed</Badge>
                                                                            )}
                                                                            {game.status === 'pending' && (
                                                                                <Badge color="warning">
                                                                                    In Progress
                                                                                </Badge>
                                                                            )}
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        openEditDialog(game);
                                                                                    }}
                                                                                    color="primary"
                                                                                    size="sm"
                                                                                >
                                                                                    Edit
                                                                                </Button>{' '}
                                                                            </>
                                                                            <>
                                                                                {' '}
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        openDeleteDialog(game);
                                                                                    }}
                                                                                    color="primary"
                                                                                    size="sm"
                                                                                >
                                                                                    Delete
                                                                                </Button>
                                                                            </>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Pagination
                        currentPage={selectedPage}
                        totalPages={totalPages}
                        changePage={(page) => setSelectedPage(page)}
                    />
                </Container>
            </div>
            <CreateNewGameDialog
                isOpen={isCreateNewGameDialogOpen}
                game={selectedGame}
                providerId={selectedProviderId}
                isEdit={isFromedit}
                onClose={(refresh = false) => {
                    setIsCreateNewGameDialogOpen(false);
                    setSelectedGame(null);
                    setIsFromEdit(false);
                    if (refresh) {
                        loadData();
                    }
                }}
            />
            <DeleteGameDialog
                isOpen={isDeleteDialogOpen}
                game={selectedGame}
                onClose={(refresh = false) => {
                    setDeleteDialogOpen(false);
                    setSelectedGame(null);
                    if (refresh) {
                        loadData();
                    }
                }}
            />
            <ToastContainer />
        </>
    );
};
