//Include Both Helper File with needed methods
import { getFirebaseBackend } from '../../../helpers/firebase_helper';
import { postJwtRegister } from '../../../helpers/fakebackend_helper';

// action
import {
    registerUserSuccessful,
    registerUserFailed,
    resetRegisterFlagChange,
    setAccountInProgressActions,
    fetchUserRelatedDataSuccessAction,
} from './reducer';
import { postRegister } from '../../../helpers/backend_helper';
import { AccountApiService } from '../../../Services/Api/Account/AccountApiService';

// initialize relavant method of both Auth
const fireBaseBackend: any = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
export const registerUser = (user: any) => async (dispatch: any) => {
    dispatch(setAccountInProgressActions('registerUser'));
    try {
        let response;
        if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
            response = fireBaseBackend.registerUser(user.email, user.password);
            // yield put(registerUserSuccessful(response));
        } else if (process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
            response = postJwtRegister('/post-jwt-register', user);
            // yield put(registerUserSuccessful(response));
        } else if (process.env.REACT_APP_API_URL) {
            response = postRegister(user);
            const data: any = await response;

            if (data.code === 101) {
                dispatch(registerUserSuccessful(data));
            } else {
                dispatch(registerUserFailed(data));
            }
        }
    } catch (error: any) {
        dispatch(registerUserFailed(error));
    }
};

export const resetRegisterFlag = () => {
    try {
        const response = resetRegisterFlagChange();
        return response;
    } catch (error) {
        return error;
    }
};

export const fetchUserRelatedData = () => async (dispatch: any) => {
    dispatch(setAccountInProgressActions('fetchUserRelatedData'));
    try {
        const data = await AccountApiService.fetchUserRelatedData();

        if (data.code === 101) {
            dispatch(fetchUserRelatedDataSuccessAction(data.data));
        }
    } catch (error) {}
};
