import { createSlice } from '@reduxjs/toolkit';
import { ISelectedGamesState } from '../types';

export const initialState: ISelectedGamesState = {
    selectedGames: {
        data: [],
        pagination: { totalPages: 0 },
    },
    isInProgress: {
        fetchSelectedGames: false,
        createSelectedGame: false,
        deleteSelectedGame: false,
        editSelectedGame: false
    },
    message: '',
    success: null,
};

const selectedGamesSlice = createSlice({
    name: 'selectedGames',
    initialState,
    reducers: {
        setSelectedGamesInProgressAction(state, action: { payload: keyof typeof initialState.isInProgress }) {
            state.isInProgress[action.payload] = true;
        },
        fetchSelectedGamesSuccessAction(state, action) {
            state.selectedGames.data = action.payload.data;
            state.selectedGames.pagination = action.payload.pagination;
            state.isInProgress.fetchSelectedGames = false;
        },
        editSelectedGamesSuccessAction(state, action) {
            state.isInProgress.editSelectedGame = false;
            state.message = action.payload.message;
            state.success = true;
        },
        deleteSelectedGamesSuccessAction(state, action) {
            state.isInProgress.deleteSelectedGame = false;
            state.message = action.payload.message;
            state.success = true;
        },
        createSelectedGameSuccessAction(state, action) {
            state.isInProgress.createSelectedGame = false;
            state.message = action.payload.message;
            state.success = true;
        },
        createSelectedGamedFailedAction(state, action) {
            state.isInProgress.createSelectedGame = false;
            state.message = action.payload.message;
            state.success = false;
        },
        resetSelectedGamesReducerStatus(state) {
            state.message = '';
            state.success = null;
        },
    },
});

export const {
    setSelectedGamesInProgressAction,
    fetchSelectedGamesSuccessAction,
    resetSelectedGamesReducerStatus,
    editSelectedGamesSuccessAction,
    deleteSelectedGamesSuccessAction,
    createSelectedGameSuccessAction,
    createSelectedGamedFailedAction,
} = selectedGamesSlice.actions;

export default selectedGamesSlice.reducer;
