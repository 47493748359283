import { useEffect, useState } from 'react';
import { getLoggedinUser } from '../../helpers/api_helper';
import { User } from '../../Models/User';
import { authService } from '../../Services/AuthService';

const useProfile = () => {
    const userProfileSession = getLoggedinUser();
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(userProfileSession ? false : true);
    const [userProfile, setUserProfile] = useState(userProfileSession ? userProfileSession : null);

    useEffect(() => {
        const userProfileSession = getLoggedinUser();
        const token = localStorage.getItem('token');
        setUserProfile(userProfileSession ? userProfileSession : null);
        setLoading(!token);
    }, []);

    return { user: authService.user!, loading, token };
};

export { useProfile };
