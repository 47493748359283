import { BaseApiService } from './BaseApiService';
import {
    IApiCommonResponse,
    ICreateSelectedOperatorApiRequestBody,
    ICreateOperatorApiRequestBody,
} from '../../slices/types';

export class OperatorApiService {
    static CREATE_OPERATOR_URL = '/operators';
    static UPDATE_OPERATOR_URL = '/operators/';

    static create(params: ICreateOperatorApiRequestBody) {
        return BaseApiService.create<IApiCommonResponse>(OperatorApiService.CREATE_OPERATOR_URL, params);
    }

    static update(id: number, params: ICreateOperatorApiRequestBody) {
        return BaseApiService.put<IApiCommonResponse>(OperatorApiService.UPDATE_OPERATOR_URL + id, params);
    }
}
