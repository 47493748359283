import { Button, Form, FormFeedback, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createSelectedOperator, editSelectedOperator } from 'slices/client-operators/thunk';
import { createNewOperatorDataSelector } from 'selectors/SelectedOperatorsSelectors';
import { loadOperatorSuggestions } from 'helpers/loadOperatorSuggestions';
import { resetSelectedOperatorsReducerStatus } from 'slices/client-operators/reducer';
import './OperatorDialog.scss';

export const CreateNewOperatorDialog = ({
    isOpen,
    onClose,
    operator,
    providerId,
    isEdit,
}: {
    isOpen: boolean;
    onClose: (refresh?: boolean) => void;
    operator?: any;
    providerId?: number;
    isEdit: boolean;
}) => {
    const dispatch: any = useDispatch();

    const { message, success, isInProgress } = useSelector(createNewOperatorDataSelector);
    const [operatorsData, setOperatorsData] = useState<any>();

    const validation: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: operator?.name || '',
            url: operator?.url || '',
            operator_id: operator?.id,
            ...(providerId ? { provider_id: providerId } : {}),
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter operator name'),
            url: Yup.string()
                .matches(
                    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
                    'Enter correct url'
                )
                .required('Please enter operator url'),
        }),
        onSubmit: (values) => {
            console.log('values', values);
            isEdit ? dispatch(editSelectedOperator(operator.id, values)) : dispatch(createSelectedOperator(values));
        },
    });

    const handleClose = (refresh = false) => {
        onClose(refresh);
        validation.resetForm();
        setOperatorsData(null);
    };

    const handleOperatorsdata = (response: any): void => {
        console.log('response', response);
        if (response.length > 0) {
            setOperatorsData(response);
        } else {
            setOperatorsData(null);
        }
    };

    useEffect(() => {
        if (!isInProgress && success !== null) {
            if (success === true) {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-success text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetSelectedOperatorsReducerStatus());
                    },
                });

                handleClose(true);
            } else {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-danger text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetSelectedOperatorsReducerStatus());
                    },
                });
            }
        }
    }, [isInProgress]);

    return (
        <Modal
            isOpen={isOpen}
            toggle={() => {
                handleClose();
            }}
            backdrop={'static'}
            id="staticBackdrop"
            centered
            className="modal-md gago"
        >
            <ModalHeader
                className="modal-title"
                id="myModalLabel"
                toggle={() => {
                    !isInProgress && handleClose();
                }}
            >
                {operator ? 'Edit the operator' : 'Create new operator'}
            </ModalHeader>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <ModalBody>
                    <div className="mb-3">
                        <label htmlFor="operatorName" className="form-label">
                            Name *
                        </label>
                        <div className="custom-dropDown-container">
                            <Input
                                type="text"
                                id="operatorName"
                                placeholder="Enter name"
                                name="name"
                                value={validation.values.name || ''}
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    loadOperatorSuggestions(true)(e.target.value, handleOperatorsdata);
                                }}
                                onKeyUp={() => {
                                    validation.setFieldValue('operator_id', 0);
                                }}
                                onBlur={validation.handleBlur}
                                invalid={validation.touched.name && validation.errors.name ? true : false}
                                autocomplete="off"
                            />
                            {operatorsData ? (
                                <div className="options">
                                    {operatorsData.map((operator: any) => (
                                        <div
                                            key={`operator-option-${operator.value}`}
                                            className="option"
                                            onClick={() => {
                                                validation.setFieldValue('operator_id', operator.value);
                                                validation.setFieldValue('name', operator.label);
                                                setOperatorsData(null);
                                            }}
                                        >
                                            {operator?.label}
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                            {validation.touched.name && validation.errors.name ? (
                                <>
                                    <div className="form-control is-invalid visually-hidden" />
                                    <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                </>
                            ) : null}
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="operatorUrl" className="form-label">
                            URL *
                        </label>
                        <Input
                            type="text"
                            id="operatorUrl"
                            placeholder="Enter url"
                            name="url"
                            value={validation.values.url || ''}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={validation.touched.url && validation.errors.url ? true : false}
                        />
                        {validation.touched.url && validation.errors.url ? (
                            <>
                                <div className="form-control is-invalid visually-hidden" />
                                <FormFeedback type="invalid">{validation.errors.url}</FormFeedback>
                            </>
                        ) : null}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={() => handleClose()} disabled={isInProgress}>
                        {operator ? 'Cancel' : 'Close'}
                    </Button>
                    <Button color="primary" type="submit" disabled={isInProgress}>
                        {isInProgress ? (
                            <span className="d-flex align-items-center">
                                <Spinner className="top-25 start-50" animation="border" color="light" />
                                <span className="flex-grow-1 ms-2">Loading...</span>
                            </span>
                        ) : operator ? (
                            'Save'
                        ) : (
                            'Create'
                        )}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};
