import React from 'react';
import Select from 'react-select';
import { JobStatuses } from '../../Services/Api/Job/IJobApiService';
import { capitalize } from '../../utils/capitalize';

type SelectJobStatusProps = {
    setValue: (status: JobStatuses) => void;
    placeholder: string;
};

const options = [
    { value: JobStatuses.ALL, label: capitalize(JobStatuses.ALL) },
    { value: JobStatuses.IN_PROGRESS, label: capitalize(JobStatuses.IN_PROGRESS) },
    { value: JobStatuses.DONE, label: capitalize(JobStatuses.DONE) },
    { value: JobStatuses.FAILED, label: capitalize(JobStatuses.FAILED) },
];

export const SelectJobStatus = ({ setValue, placeholder = 'Filter By Status...' }: SelectJobStatusProps) => {
    return (
        <Select
            isClearable={false}
            isSearchable={true}
            name="color"
            placeholder={placeholder}
            options={options}
            onChange={(e: { value: JobStatuses; label: string }) => {
                setValue(e.value);
            }}
        />
    );
};
