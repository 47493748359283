import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotFoundGames } from '../../../slices/games/thunk';
import { notFoundGamesDataSelector } from '../../../selectors/gamesSelectors';
import { timeAgo } from '../../../utils/timeAgo';
import { MatchGamesDialog } from './MatchGamesDialog';
import { ToastContainer } from 'react-toastify';
import { Search } from '../../../Components/Common/Search';
import { DropDown } from '../../../Components/Common/DropDown';
import { loadProviderSuggestions } from '../../../helpers/loadProviderSuggestions';
import { Pagination } from '../../../Components/Common/Pagination';
import { loadOperatorSuggestions } from '../../../helpers/loadOperatorSuggestions';

export const NotFoundGames = () => {
    const dispatch: any = useDispatch();
    const [isMatchGamesDialogOpen, setIsMatchGamesDialogOpen] = useState<boolean>(false);
    const [selectedGame, setSelectedGame] = useState<any>(null);
    const { data, isInProgress, totalPages } = useSelector(notFoundGamesDataSelector);

    const [selectedProviderId, setSelectedProviderId] = useState<number>(0);
    const [selectedOperatorId, setSelectedOperatorId] = useState<number>(0);
    const [selectedPage, setSelectedPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        dispatch(
            fetchNotFoundGames({
                page: selectedPage,
                q: searchTerm,
                provider_id: selectedProviderId,
                operator_id: selectedOperatorId,
            })
        );
    }, [selectedPage, searchTerm, selectedProviderId, selectedOperatorId]);

    const openMatchGamesDialog = (game: any): void => {
        setIsMatchGamesDialogOpen(true);
        setSelectedGame(game);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                                <BreadCrumb title={'Not Found Games'} />
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col xs={12} sm={4}>
                                                        <Search
                                                            onlyOnEnter={true}
                                                            search={setSearchTerm}
                                                            placeholder="Search By Game Name..."
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={4}>
                                                        <DropDown
                                                            handleSelect={setSelectedProviderId}
                                                            isInProgress={false}
                                                            loadSuggestions={() => loadProviderSuggestions()}
                                                            placeholder="Filter By Provider..."
                                                            hideImage={true}
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={4}>
                                                        <DropDown
                                                            handleSelect={(id) => {
                                                                setSelectedPage(1);
                                                                setSelectedOperatorId(id);
                                                            }}
                                                            isInProgress={false}
                                                            loadSuggestions={() => loadOperatorSuggestions()}
                                                            placeholder="Filter By Operators..."
                                                            hideImage={true}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg={12}>
                                        <Card>
                                            <CardHeader>
                                                <h4 className="card-title mb-0">Games</h4>
                                            </CardHeader>
                                            <CardBody>
                                                <div className="table-responsive">
                                                    <table className="table align-middle table-nowrap table-striped-columns mb-2">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th scope="col">ID</th>
                                                                <th scope="col" colSpan={2}>
                                                                    Name
                                                                </th>
                                                                <th scope="col">Section</th>
                                                                <th scope="col">Provider</th>
                                                                <th scope="col">Operator</th>
                                                                <th scope="col">Date</th>
                                                                <th
                                                                    scope="col"
                                                                    className="text-end"
                                                                    style={{ width: '150px' }}
                                                                >
                                                                    Action
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.map((game: any, index: number) => (
                                                                <tr key={`not-found-game-${game.nfg_id}`}>
                                                                    <td>{game.nfg_id}</td>
                                                                    <td>
                                                                        {game.nfg_name || (
                                                                            <Badge color="warning">No Name</Badge>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <img
                                                                            className="img-thumbnail"
                                                                            width={200}
                                                                            src={game.nfg_image}
                                                                        />
                                                                    </td>
                                                                    <td>{game.section_name}</td>
                                                                    <td>
                                                                        {game?.provider_name}{' '}
                                                                        {!game?.provider_id && game?.provider_name && (
                                                                            <>
                                                                                {' '}
                                                                                -{' '}
                                                                                <Badge color="danger">Not Found</Badge>
                                                                            </>
                                                                        )}
                                                                        {!game?.provider_id && !game?.provider_name && (
                                                                            <Badge color="warning">No Provider</Badge>
                                                                        )}
                                                                    </td>
                                                                    <td>{game?.operator_name}</td>
                                                                    <td>{timeAgo(game.nfg_created_at)}</td>
                                                                    <td className="text-end">
                                                                        {(game?.provider_id ||
                                                                            !game?.provider_name) && (
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() =>
                                                                                    openMatchGamesDialog(game)
                                                                                }
                                                                            >
                                                                                Match
                                                                            </Button>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Pagination currentPage={selectedPage} totalPages={totalPages} changePage={setSelectedPage} />
                </Container>
            </div>
            <MatchGamesDialog
                isOpen={isMatchGamesDialogOpen}
                game={selectedGame}
                onClose={(refresh = false) => {
                    setIsMatchGamesDialogOpen(false);
                    setSelectedGame(null);

                    if (refresh) {
                        dispatch(
                            fetchNotFoundGames({
                                page: selectedPage,
                                q: searchTerm,
                                provider_id: selectedProviderId,
                                operator_id: selectedOperatorId,
                            })
                        );
                    }
                }}
            />
            <ToastContainer />
        </React.Fragment>
    );
};
