import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAndMatchProvider, matchProviders } from '../../../slices/providers/thunk';
import { matchProvidersDataSelector } from '../../../selectors/providersSelectors';
import { toast } from 'react-toastify';
import { resetProvidersReducerStatus } from '../../../slices/providers/reducer';
import { DropDown } from 'Components/Common/DropDown';
import { loadProviderSuggestions } from '../../../helpers/loadProviderSuggestions';

export const MatchProvidersDialog = ({
    isOpen,
    onClose,
    provider,
}: {
    isOpen: boolean;
    onClose: (refresh?: boolean) => void;
    provider?: any;
}) => {
    const dispatch: any = useDispatch();
    const [selectedProviderId, setSelectedProviderId] = useState<number>(0);

    const onMatchProviders = (): void => {
        dispatch(matchProviders({ provider_id: selectedProviderId, nfp_id: provider.nfp_id }));
    };

    const onCreateNewProvider = (): void => {
        if (window.confirm(`Do you really want to create a new Provider with the name "${provider.nfp_name}"`)) {
            dispatch(createAndMatchProvider({ nfp_id: provider.nfp_id, name: provider.nfp_name }));
        }
    };

    const { message, success, isInProgress, isCreateAndMatchProviderInProgress } =
        useSelector(matchProvidersDataSelector);

    useEffect(() => {
        if (!isInProgress && !isCreateAndMatchProviderInProgress && success !== null) {
            if (success === true) {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-success text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetProvidersReducerStatus());
                    },
                });

                onClose(true);
            } else {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-danger text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetProvidersReducerStatus());
                    },
                });
            }
        }
    }, [isInProgress, isCreateAndMatchProviderInProgress]);

    return (
        <Modal
            isOpen={isOpen}
            toggle={() => {
                onClose();
            }}
            backdrop={'static'}
            id="staticBackdrop"
            centered
        >
            <ModalHeader
                className="modal-title"
                id="myModalLabel"
                toggle={() => {
                    !isInProgress && onClose();
                }}
            >
                Match The Provider
            </ModalHeader>
            <ModalBody className="text-center">
                <div className="mt-4">
                    <dl className="row mb-0">
                        <dt className="col-sm-3">Provider Name</dt>
                        <dd className="col-sm-9">{provider?.nfp_name}</dd>
                        <dt className="col-sm-3">Operator</dt>
                        <dd className="col-sm-9">{provider?.operator_name}</dd>
                    </dl>
                </div>
                <div className="mt-4">
                    <DropDown
                        handleSelect={setSelectedProviderId}
                        isInProgress={isInProgress}
                        loadSuggestions={() => loadProviderSuggestions()}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <Row>
                    <Col lg={12}>
                        <Row>
                            <Col lg={5} className="d-grid p-0">
                                <Button
                                    className="display-block full-width"
                                    color="light"
                                    onClick={() => onClose()}
                                    disabled={isInProgress || isCreateAndMatchProviderInProgress}
                                >
                                    Close
                                </Button>
                            </Col>
                            <Col lg={2} className="" />
                            <Col lg={5} className="d-grid p-0">
                                <Button
                                    color="primary"
                                    onClick={onMatchProviders}
                                    disabled={isInProgress || !selectedProviderId || isCreateAndMatchProviderInProgress}
                                >
                                    {isInProgress ? (
                                        <span className="d-flex align-items-center">
                                            <Spinner className="top-25 start-50" animation="border" color="light" />
                                            <span className="flex-grow-1 ms-2">Matching...</span>
                                        </span>
                                    ) : (
                                        'Match Providers'
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={12} className="button-disclaimer-or display-flex justify-content-center">
                        OR
                    </Col>
                    <Col lg={12} className="d-grid p-0">
                        <Button
                            color="primary"
                            onClick={onCreateNewProvider}
                            disabled={isInProgress || isCreateAndMatchProviderInProgress}
                        >
                            {isCreateAndMatchProviderInProgress ? (
                                <span className="d-flex align-items-center">
                                    <Spinner className="top-25 start-50" animation="border" color="light" />
                                    <span className="flex-grow-1 ms-2">Creating...</span>
                                </span>
                            ) : (
                                'Create new Provider'
                            )}
                        </Button>
                    </Col>
                </Row>
            </ModalFooter>
        </Modal>
    );
};
