import { createSelector } from 'reselect';
import { IReportsState } from '../slices/types';

export const reportsStateSelector = (state: any): IReportsState => state.Reports;

export const gamesOnOperatorDataSelector = createSelector(reportsStateSelector, (reports) => ({
    sections: reports.gamesOnOperator.sections,
    isInProgress: reports.isInProgress.fetchGamesOnOperator,
}));

export const gamesPositionUpdatesSelector = createSelector(reportsStateSelector, (reports) => ({
    data: reports.positionUpdates.data,
    totalPages: reports.positionUpdates.pagination.totalPages,
    isInProgress: reports.isInProgress.fetchGamesOnOperator,
}));

export const portfolioDataSelector = createSelector(reportsStateSelector, (reports) => ({
    operators: reports.portfolio.operators,
    isInProgress: reports.isInProgress.fetchPortfolio,
}));

export const scoresDataSelector = createSelector(reportsStateSelector, (reports) => ({
    operators: reports.scores.operators,
    isInProgress: reports.isInProgress.fetchScores,
}));

export const gamesOnSectionsDataSelector = createSelector(reportsStateSelector, (reports) => ({
    operators: reports.gamesOnSections.operators,
    isInProgress: reports.isInProgress.fetchGamesOnSections,
}));
