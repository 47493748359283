import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addDefaultLocale(en);

// Create formatter (English).
const timeAgoObj = new TimeAgo('en-US');

export const timeAgo = (date: string): string => {
    return timeAgoObj.format(new Date(date));
};
