import {
    fetchNotFoundProvidersSuccessAction,
    setProvidersInProgressAction,
    matchProvidersSuccessAction,
    matchProvidersFailedAction,
    fetchAllProvidersSuccessAction,
    createAndMatchProviderSuccessAction,
    mergeProvidersSuccessAction,
} from './reducer';
import {
    createAndMatchProvidersApi,
    fetchAllProvidersApi,
    fetchNotFoundProvidersApi,
    matchProvidersApi,
} from '../../helpers/backend_helper';
import {
    ICreateAndMatchProviderApiRequestBody,
    IFetchAllProvidersApiRequestBody,
    IMatchProvidersApiRequestBody,
    IMergeProvidersApiRequestBody,
} from '../types';
import { setGlobalInProgressAction } from '../global/reducer';
import { ProviderApiService } from '../../Services/Api/ProviderApiService';

export const fetchNotFoundProviders = () => async (dispatch: any) => {
    dispatch(setProvidersInProgressAction('fetchNotFoundProviders'));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await fetchNotFoundProvidersApi();

        if (data.code === 101) {
            dispatch(fetchNotFoundProvidersSuccessAction(data));
        }
    } catch (error) { }
    dispatch(setGlobalInProgressAction(false));
};

export const fetchAllProviders = (params: IFetchAllProvidersApiRequestBody) => async (dispatch: any) => {
    dispatch(setProvidersInProgressAction('fetchAllProviders'));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await fetchAllProvidersApi(params);

        if (data.code === 101) {
            dispatch(fetchAllProvidersSuccessAction(data));
        }
    } catch (error) { }
    dispatch(setGlobalInProgressAction(false));
};

export const matchProviders = (params: IMatchProvidersApiRequestBody) => async (dispatch: any) => {
    dispatch(setProvidersInProgressAction('matchProviders'));
    try {
        const data = await matchProvidersApi(params);

        if (data.code === 101) {
            dispatch(matchProvidersSuccessAction(data.message));
        } else {
            dispatch(matchProvidersFailedAction(data.message));
        }
    } catch (error) { }
};

export const mergeProviders = (params: IMergeProvidersApiRequestBody) => async (dispatch: any) => {
    dispatch(setProvidersInProgressAction('mergeProviders'));
    try {
        const data = await ProviderApiService.mergeProviders(params);

        dispatch(mergeProvidersSuccessAction({ message: data.message, success: data.code === 101 }));
    } catch (error) {}
};

export const createAndMatchProvider = (params: ICreateAndMatchProviderApiRequestBody) => async (dispatch: any) => {
    dispatch(setProvidersInProgressAction('createAndMatchProvider'));
    try {
        const data = await createAndMatchProvidersApi(params);

        if (data.code === 101) {
            dispatch(createAndMatchProviderSuccessAction(data.message));
        } else {
            dispatch(matchProvidersFailedAction(data.message));
        }
    } catch (error) { }
};
