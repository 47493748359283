import React, { useEffect, useState } from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    Row,
    Button,
    Form,
    FormFeedback,
    Alert,
    Spinner,
} from 'reactstrap';
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth';

//redux
import { useSelector, useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import withRouter from '../../Components/Common/withRouter';
// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

// actions
import { loginUser, resetLoginFlag } from '../../slices/thunks';

import logoLight from '../../assets/images/logo-light.png';
import { loginDataSelector } from '../../selectors/authSelectors';

const Login = (props: any) => {
    const dispatch: any = useDispatch();

    const { error, errorMsg } = useSelector(loginDataSelector);

    const [passwordShow, setPasswordShow] = useState<boolean>(false);

    const [loader, setLoader] = useState<boolean>(false);

    const validation: any = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            user_email: '',
            user_password: '',
        },
        validationSchema: Yup.object({
            user_email: Yup.string().required('Please Enter Your Email'),
            user_password: Yup.string().required('Please Enter Your Password'),
        }),
        onSubmit: (values) => {
            dispatch(loginUser(values, props.router.navigate));
            setLoader(true);
        },
    });

    useEffect(() => {
        if (errorMsg) {
            setTimeout(() => {
                dispatch(resetLoginFlag());
                setLoader(false);
            }, 3000);
        }
    }, [dispatch, errorMsg]);

    document.title = 'SignIn';
    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="20" />
                                        </Link>
                                    </div>
                                    <p className="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4 card-bg-fill">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Welcome Back !</h5>
                                            <p className="text-muted">Sign in to continue to Velzon.</p>
                                        </div>
                                        {error && error ? <Alert color="danger"> {error} </Alert> : null}
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                action="#"
                                            >
                                                <div className="mb-3">
                                                    <Label htmlFor="user_email" className="form-label">
                                                        Email
                                                    </Label>
                                                    <Input
                                                        name="user_email"
                                                        className="form-control"
                                                        placeholder="Enter email"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.user_email || ''}
                                                        invalid={
                                                            validation.touched.user_email &&
                                                            validation.errors.user_email
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.user_email && validation.errors.user_email ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.user_email}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <div className="float-end">
                                                        <Link to="/forgot-password" className="text-muted">
                                                            Forgot password?
                                                        </Link>
                                                    </div>
                                                    <Label className="form-label" htmlFor="user_password">
                                                        Password
                                                    </Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            name="user_password"
                                                            value={validation.values.user_password || ''}
                                                            type={passwordShow ? 'text' : 'password'}
                                                            className="form-control pe-5"
                                                            placeholder="Enter Password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.user_password &&
                                                                validation.errors.user_password
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.user_password &&
                                                        validation.errors.user_password ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.user_password}
                                                            </FormFeedback>
                                                        ) : null}
                                                        <button
                                                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                                            type="button"
                                                            id="password-addon"
                                                            onClick={() => setPasswordShow(!passwordShow)}
                                                        >
                                                            <i className="ri-eye-fill align-middle"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="mt-4">
                                                    <Button
                                                        color="success"
                                                        disabled={loader && true}
                                                        className="btn btn-success w-100"
                                                        type="submit"
                                                    >
                                                        {loader && (
                                                            <Spinner size="sm" className="me-2">
                                                                {' '}
                                                                Loading...{' '}
                                                            </Spinner>
                                                        )}
                                                        Sign In
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="mt-4 text-center">
                                    <p className="mb-0">
                                        Don't have an account ?{' '}
                                        <Link
                                            to="/register"
                                            className="fw-semibold text-primary text-decoration-underline"
                                        >
                                            {' '}
                                            Signup{' '}
                                        </Link>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(Login);
