import { Row } from 'reactstrap';

type IPaginationProps = {
    currentPage: number;
    totalPages: number;
    changePage: (page: number) => void;
};

export const Pagination = ({ currentPage, totalPages, changePage }: IPaginationProps) => {
    return (
        <Row className="g-0 justify-content-end mb-4">
            <div className="col-sm-auto">
                <ul className="pagination-block pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                    {currentPage === 1 ? (
                        <li className={'page-item disabled'}>
                            <span className="page-link">First</span>
                        </li>
                    ) : (
                        <li className={'page-item'}>
                            <span className="page-link pointer" onClick={() => changePage(1)}>
                                First
                            </span>
                        </li>
                    )}
                    {currentPage <= 1 ? (
                        <li className={'page-item disabled'}>
                            <span className="page-link">Previous</span>
                        </li>
                    ) : (
                        <li className={currentPage <= 1 ? 'page-item disabled' : 'page-item'}>
                            <span className="page-link pointer" onClick={() => changePage(currentPage - 1)}>
                                Previous
                            </span>
                        </li>
                    )}
                    {currentPage - 2 > 0 && (
                        <li className="page-item" key={`page-link-${currentPage - 2}`}>
                            <span className={'page-link pointer'} onClick={() => changePage(currentPage - 2)}>
                                {currentPage - 2}
                            </span>
                        </li>
                    )}
                    {currentPage - 1 > 0 && (
                        <li className="page-item" key={`page-link-${currentPage - 1}`}>
                            <span className={'page-link pointer'} onClick={() => changePage(currentPage - 1)}>
                                {currentPage - 1}
                            </span>
                        </li>
                    )}
                    <li className="page-item">
                        <span className={'page-link active'}>{currentPage}</span>
                    </li>
                    {currentPage + 1 <= totalPages && (
                        <li className="page-item" key={`page-link-${currentPage + 1}`}>
                            <span className={'page-link pointer'} onClick={() => changePage(currentPage + 1)}>
                                {currentPage + 1}
                            </span>
                        </li>
                    )}
                    {currentPage + 2 <= totalPages && (
                        <li className="page-item" key={`page-link-${currentPage + 2}`}>
                            <span className={'page-link pointer'} onClick={() => changePage(currentPage + 2)}>
                                {currentPage + 2}
                            </span>
                        </li>
                    )}
                    {currentPage >= totalPages ? (
                        <li className={'page-item disabled'}>
                            <span className="page-link">Next</span>
                        </li>
                    ) : (
                        <li className={currentPage >= totalPages ? 'page-item disabled' : 'page-item'}>
                            <span className="page-link pointer" onClick={() => changePage(currentPage + 1)}>
                                Next
                            </span>
                        </li>
                    )}
                    {currentPage === totalPages ? (
                        <li className={'page-item disabled'}>
                            <span className="page-link">Last ({totalPages})</span>
                        </li>
                    ) : (
                        <li className={'page-item'}>
                            <span className="page-link pointer" onClick={() => changePage(totalPages)}>
                                Last ({totalPages})
                            </span>
                        </li>
                    )}
                </ul>
            </div>
        </Row>
    );
};
