import { DropDown } from '../Common/DropDown';
import React from 'react';
import { loadProviderSuggestions } from '../../helpers/loadProviderSuggestions';

type SelectProviderProps = {
    setValue: (id: number) => void;
    isInProgress: boolean;
    placeholder?: string;
};

export const SelectProvider = ({
    setValue,
    isInProgress,
    placeholder = 'Filter By Provider...',
}: SelectProviderProps) => {
    return (
        <DropDown
            handleSelect={(id) => {
                setValue(id);
            }}
            isInProgress={isInProgress}
            loadSuggestions={() => loadProviderSuggestions()}
            placeholder={placeholder}
            hideImage={true}
        />
    );
};
