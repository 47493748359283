import { DropDown } from '../Common/DropDown';
import { loadOperatorSuggestions } from '../../helpers/loadOperatorSuggestions';
import React from 'react';

type SelectOperatorProps = {
    setValue: (id: number) => void;
    isInProgress: boolean;
    placeholder?: string;
};

export const SelectOperator = ({
    setValue,
    isInProgress,
    placeholder = 'Filter By Operator...',
}: SelectOperatorProps) => {
    return (
        <DropDown
            handleSelect={(id) => {
                setValue(id);
            }}
            isInProgress={isInProgress}
            loadSuggestions={() => loadOperatorSuggestions()}
            placeholder={placeholder}
            hideImage={true}
        />
    );
};
