import React, { useEffect } from 'react';
import {
    Row,
    Col,
    CardBody,
    Card,
    Alert,
    Container,
    Input,
    Label,
    Form,
    FormFeedback,
    Button,
    Spinner,
} from 'reactstrap';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// action
import { registerUser, resetRegisterFlag } from '../../slices/thunks';

//redux
import { useSelector, useDispatch } from 'react-redux';

import { Link, useNavigate } from 'react-router-dom';

//import images
import logoLight from '../../assets/images/logo-light.png';
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth';
import { registerDataSelector } from '../../selectors/authSelectors';

export const UserSettings = () => {
    const history = useNavigate();
    const dispatch: any = useDispatch();

    const info = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            user_email: '',
            user_name: '',
        },
        validationSchema: Yup.object({
            user_email: Yup.string().required('Please Enter Your New Email'),
            user_name: Yup.string().required('Please Enter Your Name'),
        }),
        onSubmit: (values) => {
            //dispatch(registerUser(values));
        },
    });

    const password = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            user_password: '',
            user_npassword: '',
            confirm_password: '',
        },
        validationSchema: Yup.object({
            user_npassword: Yup.string().required('Please Enter Your New Password'),
            user_password: Yup.string().required('Please Enter Your Current Password'),
            confirm_password: Yup.string()
                .oneOf([Yup.ref('user_password'), ''])
                .required('Confirm Password is required'),
        }),
        onSubmit: (values) => {
            //dispatch(registerUser(values));
        },
    });
    const { error, success, loading, registrationError } = useSelector(registerDataSelector);

    useEffect(() => {
        if (success) {
            setTimeout(() => history('/login'), 3000);
        }

        setTimeout(() => {
            dispatch(resetRegisterFlag());
        }, 10000);
    }, [dispatch, success, error, history]);

    document.title = 'User Settings | Velzon - React Admin & Dashboard Template';

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="20" />
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Edit Your Info</h5>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    info.handleSubmit();
                                                    return false;
                                                }}
                                                className="needs-validation"
                                                action="#"
                                            >
                                                {success ? (
                                                    <>
                                                        {toast('Changes Saved Successfully...', {
                                                            position: 'top-right',
                                                            hideProgressBar: false,
                                                            className: 'bg-success text-white',
                                                            progress: undefined,
                                                            toastId: '',
                                                        })}
                                                        <ToastContainer autoClose={2000} limit={1} />
                                                        <Alert color="success">
                                                            Changes Saved Successfully...
                                                        </Alert>
                                                    </>
                                                ) : null}

                                                {error ? (
                                                    <Alert color="danger">
                                                        <div>{registrationError}</div>
                                                    </Alert>
                                                ) : null}

                                                <div className="mb-3">
                                                    <Label htmlFor="useremail" className="form-label">
                                                        Email <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input
                                                        id="email"
                                                        name="user_email"
                                                        className="form-control"
                                                        placeholder="Enter new email address"
                                                        type="email"
                                                        onChange={info.handleChange}
                                                        onBlur={info.handleBlur}
                                                        value={info.values.user_email || ''}
                                                        invalid={
                                                            info.touched.user_email &&
                                                                info.errors.user_email
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {info.touched.user_email && info.errors.user_email ? (
                                                        <FormFeedback type="invalid">
                                                            <div>{info.errors.user_email}</div>
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="username" className="form-label">
                                                        UserName <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input
                                                        name="user_name"
                                                        type="text"
                                                        placeholder="Enter new username"
                                                        onChange={info.handleChange}
                                                        onBlur={info.handleBlur}
                                                        value={info.values.user_name || ''}
                                                        invalid={
                                                            info.touched.user_name &&
                                                                info.errors.user_name
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {info.touched.user_name && info.errors.user_name ? (
                                                        <FormFeedback type="invalid">
                                                            <div>{info.errors.user_name}</div>
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mt-4">
                                                    <Button
                                                        color="success"
                                                        className="w-100"
                                                        type="submit"
                                                        disabled={loading && true}
                                                    >
                                                        {loading && (
                                                            <Spinner size="sm" className="me-2">
                                                                {' '}
                                                                Loading...{' '}
                                                            </Spinner>
                                                        )}
                                                        Save
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Edit Your Password</h5>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    info.handleSubmit();
                                                    return false;
                                                }}
                                                className="needs-validation"
                                                action="#"
                                            >
                                                {success ? (
                                                    <>
                                                        {toast('Password Updated Successfully...', {
                                                            position: 'top-right',
                                                            hideProgressBar: false,
                                                            className: 'bg-success text-white',
                                                            progress: undefined,
                                                            toastId: '',
                                                        })}
                                                        <ToastContainer autoClose={2000} limit={1} />
                                                        <Alert color="success">
                                                            Password Updated Successfully...
                                                        </Alert>
                                                    </>
                                                ) : null}

                                                {error ? (
                                                    <Alert color="danger">
                                                        <div>{registrationError}</div>
                                                    </Alert>
                                                ) : null}

                                                <div className="mb-3">
                                                    <Label htmlFor="userpassword" className="form-label">
                                                        Password <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input
                                                        name="user_password"
                                                        type="password"
                                                        placeholder="Enter Current Password"
                                                        onChange={password.handleChange}
                                                        onBlur={password.handleBlur}
                                                        value={password.values.user_password || ''}
                                                        invalid={
                                                            password.touched.user_password &&
                                                                password.errors.user_password
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {password.touched.user_password &&
                                                        password.errors.user_password ? (
                                                        <FormFeedback type="invalid">
                                                            <div>{password.errors.user_password}</div>
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="userpassword" className="form-label">
                                                        Last Name <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input
                                                        name="user_npassword"
                                                        type="password"
                                                        placeholder="Enter New Password"
                                                        onChange={password.handleChange}
                                                        onBlur={password.handleBlur}
                                                        value={password.values.user_npassword || ''}
                                                        invalid={
                                                            password.touched.user_npassword &&
                                                                password.errors.user_npassword
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {password.touched.user_npassword && password.errors.user_npassword ? (
                                                        <FormFeedback type="invalid">
                                                            <div>{password.errors.user_npassword}</div>
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-2">
                                                    <Label htmlFor="confirmPassword" className="form-label">
                                                        Confirm Password <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input
                                                        name="confirm_password"
                                                        type="password"
                                                        placeholder="Confirm Password"
                                                        onChange={password.handleChange}
                                                        onBlur={password.handleBlur}
                                                        value={password.values.confirm_password || ''}
                                                        invalid={
                                                            password.touched.confirm_password &&
                                                                password.errors.confirm_password
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {password.touched.confirm_password &&
                                                        password.errors.confirm_password ? (
                                                        <FormFeedback type="invalid">
                                                            <div>{password.errors.confirm_password}</div>
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mt-4">
                                                    <Button
                                                        color="success"
                                                        className="w-100"
                                                        type="submit"
                                                        disabled={loading && true}
                                                    >
                                                        {loading && (
                                                            <Spinner size="sm" className="me-2">
                                                                {' '}
                                                                Loading...{' '}
                                                            </Spinner>
                                                        )}
                                                        Save
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};
