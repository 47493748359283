import { BaseApiService } from '../BaseApiService';
import { IUserRelatedDataResponse } from './IAccountApiService';

export class AccountApiService {
    static FETCH_USER_RELATED_DATA_URL = '/auth/related-data';

    static fetchUserRelatedData(): Promise<IUserRelatedDataResponse> {
        return BaseApiService.get<IUserRelatedDataResponse>(AccountApiService.FETCH_USER_RELATED_DATA_URL);
    }
}
