import { useEffect, useState } from 'react';
import { Alert, Badge, Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPositionUpdates } from '../../../slices/reports/thunk';
import { gamesPositionUpdatesSelector } from '../../../selectors/reportsSelectors';
import { DropDown } from '../../../Components/Common/DropDown';
import { loadProviderSuggestions } from '../../../helpers/loadProviderSuggestions';
import { loadGameSuggestions } from '../../../helpers/loadGameSuggestions';
import { loadOperatorSuggestions } from '../../../helpers/loadOperatorSuggestions';
import Flatpickr from 'react-flatpickr';
import { Pagination } from '../../../Components/Common/Pagination';
import moment from 'moment';
import { useProfile } from '../../../Components/Hooks/UserHooks';
import { SelectSelectedGame } from '../../../Components/Selects/SelectSelectedGame'
import { SelectSelectedOperator } from '../../../Components/Selects/SelectSelectedOperator'

export const PositionUpdates = () => {
    const dispatch: any = useDispatch();
    const { data, isInProgress, totalPages } = useSelector(gamesPositionUpdatesSelector);
    const { user } = useProfile();

    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [selectedProviderId, setSelectedProviderId] = useState(user.isAdmin ? 0 : user.providerId!);
    const [selectedGameIds, setSelectedGameIds] = useState<number[]>([]);
    const [selectedOperatorIds, setSelectedOperatorIds] = useState<number[]>([]);
    const [selectedPage, setSelectedPage] = useState<number>(1);

    useEffect(() => {
        dispatch(
            fetchPositionUpdates(
                selectedPage,
                10,
                selectedProviderId,
                selectedGameIds,
                selectedOperatorIds,
                moment(selectedDate).format('YYYY-MM-DD')
            )
        );
    }, [selectedPage, selectedProviderId, selectedGameIds, selectedOperatorIds, selectedDate]);

    const smColumns = user.isAdmin ? 3 : 4;

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                                <BreadCrumb pageTitle={'Reports'} title={'Position Updates'} />
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    {user.isAdmin && (
                                                        <Col xs={12} sm={smColumns}>
                                                            <DropDown
                                                                handleSelect={(id) => {
                                                                    setSelectedPage(1);
                                                                    setSelectedProviderId(id);
                                                                }}
                                                                isInProgress={isInProgress}
                                                                loadSuggestions={() => loadProviderSuggestions()}
                                                                placeholder="* Filter By Provider..."
                                                                hideImage={true}
                                                            />
                                                        </Col>
                                                    )}
                                                    <Col xs={12} sm={smColumns}>
                                                        <SelectSelectedGame
                                                            setValue={(id) => {
                                                                setSelectedPage(1);
                                                                console.log('ids', id)
                                                                setSelectedGameIds(id as any as number[]);
                                                            }}
                                                            isInProgress={isInProgress}
                                                            placeholder="Filter By Games..."
                                                            isMulti={true}
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={smColumns}>
                                                        <SelectSelectedOperator
                                                            setValue={(id) => {
                                                                setSelectedPage(1);
                                                                setSelectedOperatorIds(id as any as number[]);
                                                            }}
                                                            isInProgress={isInProgress}
                                                            placeholder="Filter By Operators..."
                                                            isMulti={true}
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={smColumns}>
                                                        <Flatpickr
                                                            className="form-control"
                                                            id="datepicker-publish-input"
                                                            placeholder="* Select a date"
                                                            options={{
                                                                altInput: true,
                                                                altFormat: 'F j, Y',
                                                                dateFormat: 'YYYY-MM-DD',
                                                            }}
                                                            onChange={(v) => {
                                                                setSelectedPage(1);
                                                                setSelectedDate(v[0]);
                                                            }}
                                                            value={selectedDate}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                {!selectedProviderId || !selectedDate ? (
                                                    <Alert color="primary" className="mb-0">
                                                        Please select required filters
                                                    </Alert>
                                                ) : isInProgress ? (
                                                    <></>
                                                ) : data.length === 0 ? (
                                                    <Alert color="primary" className="mb-0">
                                                        No data found for selected filters
                                                    </Alert>
                                                ) : (
                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-nowrap align-middle mb-0">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th>Operator</th>
                                                                    <th>Game</th>
                                                                    <th>Section</th>
                                                                    <th>Position Change</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.map((item, index) => {
                                                                    return (
                                                                        <tr key={`section-info-${item.id}`}>
                                                                            <td>{item.operator_name}</td>
                                                                            <td>{item.game_name}</td>
                                                                            <td>{item.section_name}</td>
                                                                            <td>
                                                                                <h4>
                                                                                    <Badge
                                                                                        color={
                                                                                            item.position <
                                                                                            item.old_position
                                                                                                ? 'success'
                                                                                                : 'danger'
                                                                                        }
                                                                                    >
                                                                                        {item.old_position} {'->'}{' '}
                                                                                        {item.position}
                                                                                    </Badge>
                                                                                </h4>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Pagination currentPage={selectedPage} totalPages={totalPages} changePage={setSelectedPage} />
                </Container>
            </div>
        </>
    );
};
