import React, { useEffect, useState } from 'react';
import withRouter from './withRouter';

import { useSelector } from 'react-redux';

import { createSelector } from 'reselect';

const RightSidebar = (props: any) => {
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        const sidebarColorDark = document.getElementById('sidebar-color-dark') as HTMLInputElement;
        const sidebarColorLight = document.getElementById('sidebar-color-light') as HTMLInputElement;

        if (show && sidebarColorDark && sidebarColorLight) {
            sidebarColorDark.checked = false;
            sidebarColorLight.checked = false;
        }
    }, [show]);

    const selectLayoutState = (state: any) => state.Layout;
    const selectLayoutProperties = createSelector(selectLayoutState, (layout) => ({
        preloader: layout.preloader,
    }));
    const { preloader } = useSelector(selectLayoutProperties);

    // open offcanvas

    window.onscroll = function () {
        scrollFunction();
    };

    const scrollFunction = () => {
        const element = document.getElementById('back-to-top');
        if (element) {
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                element.style.display = 'block';
            } else {
                element.style.display = 'none';
            }
        }
    };

    const toTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    const pathName = props.router.location.pathname;

    useEffect(() => {
        const preloader = document.getElementById('preloader') as HTMLElement;

        if (preloader) {
            preloader.style.opacity = '1';
            preloader.style.visibility = 'visible';

            setTimeout(function () {
                preloader.style.opacity = '0';
                preloader.style.visibility = 'hidden';
            }, 1000);
        }
    }, [pathName]);

    //Sidebar User Profile Avatar
    const [sidebarAvatar, setSidebarAvatar] = useState<boolean>(false);

    useEffect(() => {
        handleChangeSidebarAvatar(sidebarAvatar);
    }, [sidebarAvatar]);

    const handleChangeSidebarAvatar = (value: boolean) => {
        setSidebarAvatar(value);

        if (value) {
            document.documentElement.setAttribute('data-sidebar-user-show', '');
        } else {
            document.documentElement.removeAttribute('data-sidebar-user-show');
        }
    };

    return (
        <React.Fragment>
            <button onClick={() => toTop()} className="btn btn-danger btn-icon" id="back-to-top">
                <i className="ri-arrow-up-line"></i>
            </button>

            {preloader === 'enable' && (
                <div id="preloader">
                    <div id="status">
                        <div className="spinner-border text-primary avatar-sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default withRouter(RightSidebar);
