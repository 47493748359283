import { createSelector } from 'reselect';
import { accountStateSelector } from './accountSelectors';

export const loginStateSelector = (state: any) => state.Login;

export const loginDataSelector = createSelector(loginStateSelector, (login) => ({
    error: login.error,
    errorMsg: login.errorMsg,
}));

export const registerDataSelector = createSelector(accountStateSelector, (account) => {
    return {
        success: account.success,
        error: account.error,
        loading: account.loading,
        registrationError: account.registrationError,
    };
});
