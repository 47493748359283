import React from 'react';
import Select from 'react-select';
import { capitalize } from '../../utils/capitalize';
import { OperatorStatuses } from '../../Services/Api/IOperatorApiService';

type SelectOperatorStatusProps = {
    setValue: (status: OperatorStatuses) => void;
    defaultValue?: OperatorStatuses;
    placeholder: string;
};

const options = [
    { value: OperatorStatuses.ACTIVE, label: capitalize(OperatorStatuses.ACTIVE) },
    { value: OperatorStatuses.INACTIVE, label: capitalize(OperatorStatuses.INACTIVE) },
];

export const SelectJobStatus = ({
    setValue,
    placeholder = 'Filter By Status...',
    defaultValue,
}: SelectOperatorStatusProps) => {
    return (
        <Select
            isClearable={false}
            isSearchable={true}
            name="color"
            placeholder={placeholder}
            options={options}
            onChange={(e: { value: OperatorStatuses; label: string }) => {
                setValue(e.value);
            }}
            value={defaultValue ? options.find((option) => option.value === defaultValue) : undefined}
        />
    );
};
