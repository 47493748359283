import { createSelector } from 'reselect';

export const accountStateSelector = (state: any) => state.Account;

export const userSelector = createSelector(accountStateSelector, (account) => {
    return {
        user: account.user,
    };
});

export const userRelatedDataSelector = createSelector(accountStateSelector, (account) => {
    return account.relatedData;
});
