import {
    setSelectedGamesInProgressAction,
    fetchSelectedGamesSuccessAction,
    createSelectedGameSuccessAction,
    createSelectedGamedFailedAction,
    deleteSelectedGamesSuccessAction,
    editSelectedGamesSuccessAction,
} from './reducer';

import { setGlobalInProgressAction } from '../global/reducer';
import {
    IFetchSelectedGamesApiRequestBody,
    ICreateSelectedGameApiRequestBody,
    IEditSelectedGameApiRequestBody,
} from 'slices/types';
import { SelectedGameApiService } from 'Services/Api/SelectedGameApiService';
import { fetchSelectedGamesApi } from 'helpers/backend_helper';
import { fetchUserRelatedData } from '../auth/register/thunk';

export const fetchSelectedGames = (params: IFetchSelectedGamesApiRequestBody) => async (dispatch: any) => {
    dispatch(setSelectedGamesInProgressAction('fetchSelectedGames'));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await fetchSelectedGamesApi(params);

        if (data.code === 101) {
            dispatch(fetchSelectedGamesSuccessAction(data));
        }
    } catch (error) {}
    dispatch(setGlobalInProgressAction(false));
};

export const createSelectedGame = (params: ICreateSelectedGameApiRequestBody) => async (dispatch: any) => {
    dispatch(setSelectedGamesInProgressAction('createSelectedGame'));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await SelectedGameApiService.createSelectedGame(params);

        if (data.code === 101) {
            dispatch(createSelectedGameSuccessAction(data));
            dispatch(fetchUserRelatedData());
        } else {
            dispatch(createSelectedGamedFailedAction(data));
        }
    } catch (error) {}
    dispatch(setGlobalInProgressAction(false));
};

export const editSelectedGame = (id: number, params: IEditSelectedGameApiRequestBody) => async (dispatch: any) => {
    dispatch(setSelectedGamesInProgressAction('editSelectedGame'));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await SelectedGameApiService.editSelectedGame(id, params);

        if (data.code === 101) {
            dispatch(editSelectedGamesSuccessAction(data));
            dispatch(fetchUserRelatedData());
        }
    } catch (error) {}
    dispatch(setGlobalInProgressAction(false));
};

export const deleteSelectedGame = (id: number) => async (dispatch: any) => {
    dispatch(setSelectedGamesInProgressAction('deleteSelectedGame'));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await SelectedGameApiService.deleteSelectedGame(id);

        if (data.code === 101) {
            dispatch(deleteSelectedGamesSuccessAction(data));
            dispatch(fetchUserRelatedData());
        }
    } catch (error) {}
    dispatch(setGlobalInProgressAction(false));
};
