import React from 'react';
import { Navigate } from 'react-router-dom';

import Login from '../pages/Authentication/Login';
import ForgetPasswordPage from '../pages/Authentication/ForgetPassword';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';

// // User Profile
import UserProfile from '../pages/Authentication/user-profile';

import { AllGames } from '../pages/Admin/AllGames';
import { AllOperators } from '../pages/Admin/AllOperators/AllOperators';
import { NotFoundGames } from '../pages/Admin/NotFoundGames/NotFoundGames';
import { NotFoundProviders } from '../pages/Admin/NotFoundProviders/NotFoundProviders';
import { GamesOnOperator } from '../pages/Reports/GamesOnOperator/GamesOnOperator';
import { Portfolio } from '../pages/Reports/Portfolio/Portfolio'
import { Scores } from '../pages/Reports/Scores/Scores';
import { AllProviders } from '../pages/Admin/AllProviders/AllProviders';
import { UserSettings } from 'pages/Authentication/UserSettings';
import { GamesOnSections } from '../pages/Reports/GamesOnSections/GamesOnSections';
import { PositionUpdates } from '../pages/Reports/PositionUpdates/PositionUpdates';
import { Operators } from 'pages/Clients/SelectedOperators/Operators';
import { Games } from 'pages/Clients/SelectedGames/Games';
import { AllJobs } from '../pages/Admin/AllJobs';

const authProtectedRoutes = [
    // Admin
    { path: '/games/all', component: <AllGames /> },
    { path: '/operators/all', component: <AllOperators /> },
    { path: '/providers/all', component: <AllProviders /> },
    { path: '/games/not-found', component: <NotFoundGames /> },
    { path: '/providers/not-found', component: <NotFoundProviders /> },
    { path: '/reports/games-on-operator', component: <GamesOnOperator /> },
    { path: '/reports/portfolio', component: <Portfolio /> },
    { path: '/reports/games-on-sections', component: <GamesOnSections /> },
    { path: '/reports/position-updates', component: <PositionUpdates /> },
    { path: '/reports/scores', component: <Scores /> },
    { path: '/configs/operators', component: <Operators /> },
    { path: '/configs/games', component: <Games /> },
    { path: '/jobs/all', component: <AllJobs /> },

    //User Profile
    { path: '/profile', component: <UserProfile /> },

    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    {
        path: '/',
        exact: true,
        component: <Navigate to="/dashboard" />,
    },
    { path: '*', component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
    // Authentication Page
    { path: '/logout', component: <Logout /> },
    { path: '/login', component: <Login /> },
    { path: '/forgot-password', component: <ForgetPasswordPage /> },
    { path: '/register', component: <Register /> },
    { path: '/user/settings', component: <UserSettings /> },
];

export { authProtectedRoutes, publicRoutes };
