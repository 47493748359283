import { IApiCommonResponse, ICreateSelectedGameApiRequestBody } from "slices/types";
import { BaseApiService } from "./BaseApiService";


export class SelectedGameApiService {
    static CREATE_SELECTED_GAME_URL = '/selected-games';
    static DELETE_SELECTED_GAME_URL = '/selected-games/';
    static UPDATE_SELECTED_GAME_URL = '/selected-games/';

    static createSelectedGame(params: ICreateSelectedGameApiRequestBody) {
        return BaseApiService.create<IApiCommonResponse>(SelectedGameApiService.CREATE_SELECTED_GAME_URL, params);
    }

    static editSelectedGame(id: number, params: ICreateSelectedGameApiRequestBody) {
        return BaseApiService.put<IApiCommonResponse>(`${SelectedGameApiService.UPDATE_SELECTED_GAME_URL}${id}`, params);
    }

    static deleteSelectedGame(id: number) {
        return BaseApiService.delete<IApiCommonResponse>(`${SelectedGameApiService.DELETE_SELECTED_GAME_URL}${id}`);
    }
}
