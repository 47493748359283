import { createSelector } from 'reselect';
import { IOperatorsState } from '../slices/types';

export const operatorsStateSelector = (state: any): IOperatorsState => state.Operators;

export const allOperatorsDataSelector = createSelector(operatorsStateSelector, (operators) => ({
    data: operators.allOperators.data,
    isInProgress: operators.isInProgress.fetchAllOperators,
    totalPages: operators.allOperators.pagination.totalPages,
}));

export const createNewOperatorDataSelector = createSelector(operatorsStateSelector, (operators) => ({
    isInProgress: operators.isInProgress.createOperator || operators.isInProgress.updateOperator,
    message: operators.message,
    success: operators.success,
}));
