import { createSlice } from '@reduxjs/toolkit';
import { ISelectedOperatorsState } from '../types';

export const initialState: ISelectedOperatorsState = {
    selectedOperators: {
        data: [],
        pagination: { totalPages: 0 },
    },
    isInProgress: {
        fetchSelectedOperators: false,
        createSelectedOperator: false,
        deleteSelectedOperator: false,
        editSelectedOperator: false
    },
    message: '',
    success: null,
};

const selectedOperatorsSlice = createSlice({
    name: 'selectedOperators',
    initialState,
    reducers: {
        setSelectedOperatorsInProgressAction(state, action: { payload: keyof typeof initialState.isInProgress }) {
            state.isInProgress[action.payload] = true;
        },
        fetchSelectedOperatorsSuccessAction(state, action) {
            state.selectedOperators.data = action.payload.data;
            state.selectedOperators.pagination = action.payload.pagination;
            state.isInProgress.fetchSelectedOperators = false;
        },
        editSelectedOperatorsSuccessAction(state, action) {
            state.isInProgress.editSelectedOperator = false;
            state.message = action.payload.message;
            state.success = true;
        },
        deleteSelectedOperatorsSuccessAction(state, action) {
            state.isInProgress.deleteSelectedOperator = false;
            state.message = action.payload.message;
            state.success = true;
        },
        createSelectedOperatorSuccessAction(state, action) {
            state.isInProgress.createSelectedOperator = false;
            state.message = action.payload.message;
            state.success = true;
        },
        createSelectedOperatorFailedAction(state, action) {
            state.isInProgress.createSelectedOperator = false;
            state.message = action.payload.message;
            state.success = false;
        },
        resetSelectedOperatorsReducerStatus(state) {
            state.message = '';
            state.success = null;
        },
    },
});

export const {
    setSelectedOperatorsInProgressAction,
    fetchSelectedOperatorsSuccessAction,
    resetSelectedOperatorsReducerStatus,
    editSelectedOperatorsSuccessAction,
    deleteSelectedOperatorsSuccessAction,
    createSelectedOperatorSuccessAction,
    createSelectedOperatorFailedAction,
} = selectedOperatorsSlice.actions;

export default selectedOperatorsSlice.reducer;
