import { createSlice } from '@reduxjs/toolkit';
import { IGlobalState } from '../types';

export const initialState: IGlobalState = {
    isInProgress: false,
};

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setGlobalInProgressAction(state, action: { payload: boolean }) {
            state.isInProgress = action.payload;
        },
    },
});

export const {
    setGlobalInProgressAction,
} = globalSlice.actions;

export default globalSlice.reducer;
