import { IFetchAllGamesApiRequestBody } from 'slices/types';
import { fetchAllGamesApi } from './backend_helper';


export const loadClinetGameSuggestions = () => (params: IFetchAllGamesApiRequestBody, callback: (response: any) => void) => {
    fetchAllGamesApi(params).then((response: any) => {
        return callback(
            response.data.map((item: { name: string; id: number }) => {
                return {
                    label: item.name,
                    value: item.id,
                };
            })
        );
    });
};


