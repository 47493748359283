import { DropDown } from '../Common/DropDown';
import React from 'react';
import { loadGameSuggestions } from '../../helpers/loadGameSuggestions';

type SelectGameProps = {
    setValue: (id: number) => void;
    isInProgress: boolean;
    placeholder?: string;
    providerId?: number;
};

export const SelectGame = ({
    setValue,
    isInProgress,
    placeholder = 'Filter By Game...',
    providerId = 0,
}: SelectGameProps) => {
    return (
        <DropDown
            handleSelect={(id) => {
                setValue(id);
            }}
            isInProgress={isInProgress}
            loadSuggestions={() => loadGameSuggestions(providerId)}
            placeholder={placeholder}
            hideImage={true}
        />
    );
};
