import {
    fetchAllGamesSuccessAction,
    fetchNotFoundGamesSuccessAction,
    setGamesInProgressAction,
    matchGamesSuccessAction,
    matchGamesFailedAction,
    createAndMatchGameSuccessAction,
} from './reducer';
import {
    fetchNotFoundGamesApi,
    matchGamesApi,
    fetchAllGamesApi,
    createAndMatchGameApi,
} from '../../helpers/backend_helper';
import {
    ICreateAndMatchGameApiRequestBody,
    IFetchAllGamesApiRequestBody,
    IFetchNotFoundGamesApiRequestBody,
    IMatchGamesApiRequestBody,
} from '../types';
import { setGlobalInProgressAction } from 'slices/global/reducer';

export const fetchAllGames = (params: IFetchAllGamesApiRequestBody) => async (dispatch: any) => {
    dispatch(setGamesInProgressAction('fetchAllGames'));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await fetchAllGamesApi(params);

        if (data.code === 101) {
            dispatch(fetchAllGamesSuccessAction(data));
        }
    } catch (error) {}
    dispatch(setGlobalInProgressAction(false));
};

export const fetchNotFoundGames = (params: IFetchNotFoundGamesApiRequestBody) => async (dispatch: any) => {
    dispatch(setGamesInProgressAction('fetchNotFoundGames'));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await fetchNotFoundGamesApi(params);

        if (data.code === 101) {
            dispatch(fetchNotFoundGamesSuccessAction(data));
        }
    } catch (error) {}
    dispatch(setGlobalInProgressAction(false));
};

export const matchGames = (params: IMatchGamesApiRequestBody) => async (dispatch: any) => {
    dispatch(setGamesInProgressAction('matchGames'));
    try {
        const data = await matchGamesApi(params);

        if (data.code === 101) {
            dispatch(matchGamesSuccessAction(data.message));
        } else {
            dispatch(matchGamesFailedAction(data.message));
        }
    } catch (error) {}
};

export const createAndMatchGame = (params: ICreateAndMatchGameApiRequestBody) => async (dispatch: any) => {
    dispatch(setGamesInProgressAction('createAndMatchGame'));
    try {
        const data = await createAndMatchGameApi(params);

        if (data.code === 101) {
            dispatch(createAndMatchGameSuccessAction(data.message));
        } else {
            dispatch(matchGamesFailedAction(data.message));
        }
    } catch (error) {}
};
