import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash-es';
import { PropsValue } from 'react-select/dist/declarations/src/types';

type IDropDownProps = {
    handleSelect: (selectedOptionId: any, selectedOption: any) => void;
    isInProgress: boolean;
    loadSuggestions: (...args: any) => any;
    placeholder?: string;
    hideImage?: boolean;
    imageSize?: number;
    isMulti?: boolean;
    defaultOptions?: any[];
    defaultValue?: number | any;
};

export const DropDown = ({
    handleSelect,
    isInProgress,
    loadSuggestions,
    placeholder = 'Search...',
    hideImage = false,
    imageSize = 50,
    isMulti = false,
    defaultOptions = [],
    defaultValue = null,
}: IDropDownProps) => {
    const _loadSuggestions = debounce(loadSuggestions(), 300);

    return (
        <AsyncSelect
            defaultValue={defaultValue}
            isDisabled={isInProgress}
            loadOptions={_loadSuggestions}
            placeholder={placeholder}
            defaultOptions={defaultOptions}
            formatOptionLabel={(option: { label: string; image?: string; value: number }) => (
                <div className="hstack gap-3" key={`option-${option.value}`}>
                    {!hideImage && <img className=" img-thumbnail" width={imageSize} src={option.image} />}
                    <div className=" p-1 px-2">{option.label}</div>
                </div>
            )}
            isMulti={isMulti}
            isClearable={true}
            onChange={(res) => {
                if (isMulti) {
                    // @ts-ignore
                    handleSelect(res?.map((i: { value: any }) => i.value) || [], res);
                } else {
                    // @ts-ignore
                    handleSelect(res?.value || 0, res);
                }
            }}
        />
    );
};
