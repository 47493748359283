import React, { useEffect } from 'react';
import {
    Row,
    Col,
    CardBody,
    Card,
    Alert,
    Container,
    Input,
    Label,
    Form,
    FormFeedback,
    Button,
    Spinner,
} from 'reactstrap';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// action
import { registerUser, resetRegisterFlag } from '../../slices/thunks'

//redux
import { useSelector, useDispatch } from 'react-redux';

import { Link, useNavigate } from 'react-router-dom';

//import images
import logoLight from '../../assets/images/logo-light.png';
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth';
import { registerDataSelector } from '../../selectors/authSelectors';

const Register = () => {
    const history = useNavigate();
    const dispatch: any = useDispatch();

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            user_email: '',
            user_fname: '',
            user_lname: '',
            user_password: '',
            confirm_password: '',
        },
        validationSchema: Yup.object({
            user_email: Yup.string().required('Please Enter Your Email'),
            user_fname: Yup.string().required('Please Enter Your First Name'),
            user_lname: Yup.string().required('Please Enter Your Last Name'),
            user_password: Yup.string().required('Please Enter Your Password'),
            confirm_password: Yup.string()
                .oneOf([Yup.ref('user_password'), ''])
                .required('Confirm Password is required'),
        }),
        onSubmit: (values) => {
            dispatch(registerUser(values));
        },
    });

    const { error, success, loading, registrationError } = useSelector(registerDataSelector);

    useEffect(() => {
        if (success) {
            setTimeout(() => history('/login'), 3000);
        }

        setTimeout(() => {
            dispatch(resetRegisterFlag());
        }, 10000);
    }, [dispatch, success, error, history]);

    document.title = 'Basic SignUp | Velzon - React Admin & Dashboard Template';

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="20" />
                                        </Link>
                                    </div>
                                    <p className="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Create New Account</h5>
                                            <p className="text-muted">Get your free velzon account now</p>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                className="needs-validation"
                                                action="#"
                                            >
                                                {success ? (
                                                    <>
                                                        {toast('Your Redirect To Login Page...', {
                                                            position: 'top-right',
                                                            hideProgressBar: false,
                                                            className: 'bg-success text-white',
                                                            progress: undefined,
                                                            toastId: '',
                                                        })}
                                                        <ToastContainer autoClose={2000} limit={1} />
                                                        <Alert color="success">
                                                            Register User Successfully and Your Redirect To Login
                                                            Page...
                                                        </Alert>
                                                    </>
                                                ) : null}

                                                {error ? (
                                                    <Alert color="danger">
                                                        <div>{registrationError}</div>
                                                    </Alert>
                                                ) : null}

                                                <div className="mb-3">
                                                    <Label htmlFor="useremail" className="form-label">
                                                        Email <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input
                                                        id="email"
                                                        name="user_email"
                                                        className="form-control"
                                                        placeholder="Enter email address"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.user_email || ''}
                                                        invalid={
                                                            validation.touched.user_email &&
                                                            validation.errors.user_email
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.user_email && validation.errors.user_email ? (
                                                        <FormFeedback type="invalid">
                                                            <div>{validation.errors.user_email}</div>
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="username" className="form-label">
                                                        First Name <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input
                                                        name="user_fname"
                                                        type="text"
                                                        placeholder="Enter username"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.user_fname || ''}
                                                        invalid={
                                                            validation.touched.user_fname &&
                                                            validation.errors.user_fname
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.user_fname && validation.errors.user_fname ? (
                                                        <FormFeedback type="invalid">
                                                            <div>{validation.errors.user_fname}</div>
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="username" className="form-label">
                                                        Last Name <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input
                                                        name="user_lname"
                                                        type="text"
                                                        placeholder="Enter username"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.user_lname || ''}
                                                        invalid={
                                                            validation.touched.user_lname &&
                                                            validation.errors.user_lname
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.user_lname && validation.errors.user_lname ? (
                                                        <FormFeedback type="invalid">
                                                            <div>{validation.errors.user_lname}</div>
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="userpassword" className="form-label">
                                                        Password <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input
                                                        name="user_password"
                                                        type="password"
                                                        placeholder="Enter Password"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.user_password || ''}
                                                        invalid={
                                                            validation.touched.user_password &&
                                                            validation.errors.user_password
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.user_password &&
                                                    validation.errors.user_password ? (
                                                        <FormFeedback type="invalid">
                                                            <div>{validation.errors.user_password}</div>
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-2">
                                                    <Label htmlFor="confirmPassword" className="form-label">
                                                        Confirm Password <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input
                                                        name="confirm_password"
                                                        type="password"
                                                        placeholder="Confirm Password"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.confirm_password || ''}
                                                        invalid={
                                                            validation.touched.confirm_password &&
                                                            validation.errors.confirm_password
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.confirm_password &&
                                                    validation.errors.confirm_password ? (
                                                        <FormFeedback type="invalid">
                                                            <div>{validation.errors.confirm_password}</div>
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-4">
                                                    <p className="mb-0 fs-12 text-muted fst-italic">
                                                        By registering you agree to the RankRadar&nbsp;
                                                        <Link
                                                            to="#"
                                                            className="text-primary text-decoration-underline fst-normal fw-medium"
                                                        >
                                                            Terms of Use
                                                        </Link>
                                                    </p>
                                                </div>

                                                <div className="mt-4">
                                                    <Button
                                                        color="success"
                                                        className="w-100"
                                                        type="submit"
                                                        disabled={loading && true}
                                                    >
                                                        {loading && (
                                                            <Spinner size="sm" className="me-2">
                                                                {' '}
                                                                Loading...{' '}
                                                            </Spinner>
                                                        )}
                                                        Sign Up
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-4 text-center">
                                    <p className="mb-0">
                                        Already have an account ?{' '}
                                        <Link
                                            to="/login"
                                            className="fw-semibold text-primary text-decoration-underline"
                                        >
                                            {' '}
                                            Signin{' '}
                                        </Link>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default Register;
