import { IUserApiResponse } from '../slices/types';

export class User {
    private _id: number;
    private _fname: string;
    private _lname: string;
    private _type: IUserApiResponse['user_type'];
    private _email: string;
    private _provider_id?: number;

    constructor(data: IUserApiResponse) {
        this._id = data?.user_id;
        this._fname = data?.user_fname;
        this._lname = data?.user_lname;
        this._type = data?.user_type;
        this._email = data?.user_email;
        this._provider_id = data?.user_provider_id;
    }

    get id(): number {
        return this._id;
    }

    get isAdmin(): boolean {
        return this._type === 'admin';
    }

    get name(): string {
        return `${this._fname} ${this._lname}`;
    }

    get fname(): string {
        return this._fname;
    }

    set fname(value: string) {
        this._fname = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get lname(): string {
        return this._lname;
    }

    set lname(value: string) {
        this._lname = value;
    }

    get type(): IUserApiResponse['user_type'] {
        return this._type;
    }

    get providerId(): number {
        return this._provider_id || 0;
    }

    get loggedIn(): boolean {
        return !!this.id;
    }
}
