import { DropDown } from '../Common/DropDown';
import React from 'react';
import { loadSelectedOperatorSuggestions } from '../../helpers/loadSelectedOperatorSuggestions';
import { useSelector } from 'react-redux';
import { userRelatedDataSelector } from '../../selectors/accountSelectors';
import { IUserRelatedOperator } from '../../Services/Api/Account/IAccountApiService';

type SelectSelectedOperatorProps = {
    setValue: (id: number) => void;
    isInProgress: boolean;
    placeholder?: string;
    isMulti?: boolean;
    defaultValue?: number;
};

export const SelectSelectedOperator = ({
    setValue,
    isInProgress,
    placeholder = 'Filter By Operator...',
    isMulti = false,
    defaultValue = 0
}: SelectSelectedOperatorProps) => {
    const { operators } = useSelector(userRelatedDataSelector);

    return (
        <DropDown
            handleSelect={(id) => {
                setValue(id);
            }}
            defaultOptions={operators.map((operator: IUserRelatedOperator) => {
                return { label: operator.name, value: operator.id };
            })}
            isInProgress={isInProgress}
            loadSuggestions={() => loadSelectedOperatorSuggestions()}
            placeholder={placeholder}
            hideImage={true}
            isMulti={isMulti}
        />
    );
};
