import { createSelector } from 'reselect';
import { ISelectedOperatorsState } from '../slices/types';

export const selectedOperatorsStateSelector = (state: any): ISelectedOperatorsState => state.ClientOperators;

export const selectedOperatorsDataSelector = createSelector(selectedOperatorsStateSelector, (operators) => ({
    data: operators.selectedOperators.data,
    isInProgress: operators.isInProgress.fetchSelectedOperators,
    totalPages: operators.selectedOperators.pagination.totalPages,
}));

export const createNewOperatorDataSelector = createSelector(selectedOperatorsStateSelector, (operators) => ({
    isInProgress: operators.isInProgress.createSelectedOperator || operators.isInProgress.editSelectedOperator,
    message: operators.message,
    success: operators.success,
}));

export const createDeleteOperatorDataSelector = createSelector(selectedOperatorsStateSelector, (operators) => ({
    isInProgress: operators.isInProgress.deleteSelectedOperator,
    message: operators.message,
    success: operators.success,
}));