import { ICreateSelectedOperatorApiRequestBody, IApiCommonResponse } from "slices/types";
import { BaseApiService } from "./BaseApiService";


export class SelectedOperatorApiService {
    static CREATE_SELECTED_OPERATOR_URL = '/selected-operators';
    static DELETE_SELECTED_OPERATOR_URL = '/selected-operators/';
    static UPDATE_SELECTED_OPERATOR_URL = '/selected-operators/';

    static createSelectedOperator(params: ICreateSelectedOperatorApiRequestBody) {
        return BaseApiService.create<IApiCommonResponse>(SelectedOperatorApiService.CREATE_SELECTED_OPERATOR_URL, params);
    }

    static editSelectedOperator(id: number, params: ICreateSelectedOperatorApiRequestBody) {
        return BaseApiService.put<IApiCommonResponse>(`${SelectedOperatorApiService.UPDATE_SELECTED_OPERATOR_URL}${id}`, params);
    }

    static deleteSelectedOperator(id: number) {
        return BaseApiService.delete<IApiCommonResponse>(`${SelectedOperatorApiService.DELETE_SELECTED_OPERATOR_URL}${id}`);
    }
}
