import { User } from '../Models/User';

class AuthService {
    private _user: User | undefined;

    get user(): User | undefined {
        return this._user;
    }

    set user(value: User) {
        this._user = value;
    }
}

export const authService = new AuthService()
